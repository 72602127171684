import React, { Component } from 'react';
import axios from 'axios';

import { i18n } from '../components/i18n';
import { withTranslation } from 'react-i18next';

import WebcamComponent from '../components/become-components/webcam-component';
import CanvasBehavior from '../components/background-canvas-component';
import Constants from '../components/constants';
import { thematics } from '../components/constants';
import Layout from '../components/layout';

import './become.scss';
import CountrySelect from '../components/country-select';

let childrenImage = require('../images/children-become.png');

class BecomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            nickname: '',
            age: undefined,
            role: '',
            country: '',
            selectedThematic: { key: '' },
            selectedQuestion: '',
            selectedQuestionBody: '',
            parentsName: '',
            phone: '',
            email: '',

            agree_1: false,
            agree_2: false,
            thematics: thematics,
            isRecording: false,
            videoBlob: undefined,
            errorStatus: ''
        };

        this.updateName = this.updateName.bind(this);
        this.updateNickname = this.updateNickname.bind(this);
        this.updateAge = this.updateAge.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.onCountryChanged = this.onCountryChanged.bind(this);
        this.onThematicChanged = this.onThematicChanged.bind(this);
        this.onQuestionChanged = this.onQuestionChanged.bind(this);
        this.onAgree1Update = this.onAgree1Update.bind(this);
        this.onAgree2Update = this.onAgree2Update.bind(this);

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartRecording = this.handleStartRecording.bind(this);
        this.handleWebcamVideoStored = this.handleWebcamVideoStored.bind(this);
        this.switchRecording = this.switchRecording.bind(this);
    }

    updateName(event) {
        this.setState({ name: event.target.value });
    }
    updateNickname(event) {
        this.setState({ nickname: event.target.value });
    }

    updateAge(event) {
        const newAge = event.target.value;
        if (newAge < 1 || newAge > 17) return;
        this.setState({ age: newAge });
    }
    updateRole(event) {
        this.setState({ role: event.target.value });
    }

    onAgree1Update(event) {
        this.setState({
            agree_1: event.target.value
        });
    }
    onAgree2Update(event) {
        this.setState({
            agree_2: event.target.value
        });
    }

    switchRecording(status) {
        this.setState({ isRecording: status });
    }
    handleStartRecording(event) {
        event.preventDefault();
        this.switchRecording(true);
    }

    handleWebcamVideoStored(blob, thumbnailBlob) {
        this.setState({
            videoBlob: blob
        });
        console.log('Webcam video stored', blob);

        if (blob === undefined) {
            console.warn('No video stored yet!');
            console.log(blob);
            return;
        }

        let data = new FormData();
        // data.append('name', 'video.webm');
        data.append('file', blob);
        data.append('thumbnail', thumbnailBlob);
        let config = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios
            .post(Constants.SERVER_ADDRESS + 'uploadVideo', data, config)
            .then(res => {
                let request = {
                    childName: this.state.name,
                    videoUrl: res.data.fileDownloadUri,
                    thumbnail: res.data.thumbnailDownloadUri,
                    category: this.state.selectedThematic.key,
                    childNickname: this.state.nickname,
                    age: this.state.age,
                    childRole: this.state.role,
                    country: this.state.country,
                    question: this.state.selectedQuestionBody.body,
                    parentsName: this.state.parentsName,
                    phone: this.state.phone,
                    email: this.state.email
                };

                console.log('request', request);
                axios.post(Constants.SERVER_ADDRESS + 'video', request).then(res => {
                    if (res === 200) console.log('Video registered succesfully');
                    this.setState({ isRecording: false });
                    window.location.href = '/ro/explore?success=true';
                });
            })
            .catch(error => {
                console.log('Err', error);
                this.setState({
                    errorStatus:
                        'There seem to be a problem with the upload. Please try again or contact us if the problem persists.',
                    isRecording: false
                });
            });
    }

    onThematicChanged(event) {
        this.setState({
            selectedThematic: this.state.thematics.find(t => t.key === event.target.value)
        });
    }
    onQuestionChanged(event) {
        let selectedQuestionBody = this.state.selectedThematic.questions.find(q => q.id == event.target.value);

        this.setState({
            selectedQuestion: event.target.value,
            selectedQuestionBody: selectedQuestionBody
        });
    }

    onCountryChanged(event) {
        this.setState({
            country: event.target.value
        });
    }

    render() {
        const { t, i18n } = this.props;

        return (
            <Layout>
                <div className="become-page__wrapper">
                    <header>
                        <h1>
                            {i18n.language === 'ro' ? 'Participă' : 'Become'}
                            <img src={childrenImage} />
                        </h1>
                    </header>
                    <CanvasBehavior />

                    <form className="become-page__form" onSubmit={this.handleStartRecording}>
                        <h2>{t('become_title_1')}</h2>
                        <hr />
                        <p>{t('become_desc')}</p>
                        <p>{t('become_desc_2')}</p>
                        <ul>
                            <li>{t('become_faza_1')}</li>
                            <li>{t('become_faza_2')}</li>
                            <li>{t('become_faza_3')}</li>
                        </ul>
                        <h3>{t('become_title_2')}</h3>
                        <input
                            required
                            placeholder={t('name_placeholder')}
                            value={this.state.name}
                            onChange={this.updateName}
                        />

                        <input
                            required
                            value={this.state.nickname}
                            onChange={this.updateNickname}
                            placeholder={t('nickname_placeholder')}
                        />
                        <input
                            required
                            value={this.state.age}
                            onChange={this.updateAge}
                            placeholder={t('age_placeholder')}
                            type="number"
                        />
                        <input
                            required
                            value={this.state.role}
                            onChange={this.updateRole}
                            placeholder={t('role_placeholder')}
                        />
                        <CountrySelect
                            placeholder={t('country_placeholder')}
                            value={this.state.country}
                            onChange={this.onCountryChanged}
                        />
                        {this.state.thematics && (
                            <select required onChange={this.onThematicChanged} value={this.state.selectedThematic.key}>
                                <option value="" disabled defaultValue hidden>
                                    {t('thematic_placeholder')}
                                </option>
                                {this.state.thematics.map(thematic => {
                                    return (
                                        <option key={thematic.key} value={thematic.key}>
                                            {thematic.name}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                        {this.state.selectedThematic.questions && (
                            <select required onChange={this.onQuestionChanged} value={this.state.selectedQuestion}>
                                <option value="" disabled defaultValue hidden>
                                    {t('question_placeholder')}
                                </option>
                                {this.state.selectedThematic.questions.map(question => {
                                    return (
                                        <option key={question.id} value={question.id} title={question.body}>
                                            {question.body}
                                        </option>
                                    );
                                })}
                            </select>
                        )}

                        <input
                            required
                            placeholder={t('parents_placeholder')}
                            value={this.state.parentsName}
                            onChange={ev => this.setState({ parentsName: ev.target.value })}
                        />

                        <input
                            required
                            placeholder={t('phone_placeholder')}
                            value={this.state.phone}
                            onChange={ev => this.setState({ phone: ev.target.value })}
                        />
                        <input
                            required
                            type="email"
                            placeholder={t('email_placeholder')}
                            value={this.state.email}
                            onChange={ev => this.setState({ email: ev.target.value })}
                        />

                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    required
                                    value={this.state.agree_1}
                                    onChange={this.onAgree1Update}
                                />{' '}
                                {/* /terms */}
                                {t('agree_label_1')}{' '}
                                <a
                                    href={
                                        i18n.language === 'ro'
                                            ? 'https://docs.google.com/document/d/1rshmU3pyiN2RomPA68bcL3qZBItBXRP4MbP6MWdouWw/edit'
                                            : 'https://docs.google.com/document/d/1yz7oal43opqrs3ip0Q93-riY-tc2KuYv6xlrslUeB7M/edit'
                                    }
                                >
                                    {t('agree_label_2')}
                                </a>{' '}
                                {t('agree1_label_3')}
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    required
                                    value={this.state.agree_2}
                                    onChange={this.onAgree2Update}
                                />{' '}
                                {t('agree_label_1')}{' '}
                                <a
                                    href={
                                        i18n.language === 'ro'
                                            ? 'https://docs.google.com/document/d/1yz7oal43opqrs3ip0Q93-riY-tc2KuYv6xlrslUeB7M/edit'
                                            : 'https://docs.google.com/document/d/1rshmU3pyiN2RomPA68bcL3qZBItBXRP4MbP6MWdouWw/edit'
                                    }
                                >
                                    {t('agree_label_2')}
                                </a>{' '}
                                {t('agree2_label_3')}
                            </label>
                        </div>
                        <button className="submit-button" type="submit">
                            {t('start_recording_but')}
                        </button>
                        {/* <button type="submit">Submit</button> */}
                    </form>

                    {this.state.errorStatus && this.state.errorStatus !== '' && (
                        <div
                            className="error-status"
                            onClick={() => {
                                this.setState({ errorStatus: '' });
                            }}
                        >
                            {this.state.errorStatus}
                        </div>
                    )}
                </div>
                {this.state.isRecording && (
                    <div className="become-page__recorder">
                        <WebcamComponent
                            onWebcamVideoStored={this.handleWebcamVideoStored}
                            onExit={() => this.switchRecording(false)}
                            question={this.state.selectedQuestionBody.body}
                            t={t}
                        />
                    </div>
                )}
            </Layout>
        );
    }
}

export default withTranslation()(BecomePage);
