import React, { Component } from 'react';

import './loading-component.scss';

class LoadingComponent extends Component {
    render() {
        return <div className="lds-dual-ring" />;
    }
}

export default LoadingComponent;
