import React, { Component } from 'react';
import LoadingComponent from '../loading-component';
import './webcam-component.scss';

let MediaCapturer;

let recordImg = require('../../images/record-circular-button.png');
let resumeImg = require('../../images/play-button.png');
let pauseImg = require('../../images/pause-button.png');
let previewImg = require('../../images/eye.png');
let undoImg = require('../../images/undo-button.png');
let doneImg = require('../../images/checkmark-for-verification.png');

class WebcamComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMedia: false,
            granted: false,
            rejectedReason: '',
            recording: false,
            paused: false,
            isInPreview: false,
            isSendingToServer: false,

            blob: null,
            thumbnail: undefined
        };

        this.myVideoRef = React.createRef();

        this.handleRequest = this.handleRequest.bind(this);
        this.handleGranted = this.handleGranted.bind(this);
        this.handleDenied = this.handleDenied.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleResume = this.handleResume.bind(this);
        this.handleStreamClose = this.handleStreamClose.bind(this);
        this.setStreamToVideo = this.setStreamToVideo.bind(this);
        this.releaseStreamFromVideo = this.releaseStreamFromVideo.bind(this);
        this.downloadVideo = this.downloadVideo.bind(this);
        this.onRedo = this.onRedo.bind(this);
        this.onAccepted = this.onAccepted.bind(this);
    }

    componentDidMount() {
        let reactMultimedia = require('react-multimedia-capture');
        console.log('rm', reactMultimedia);
        MediaCapturer = reactMultimedia.default;
        console.log('MC', MediaCapturer);
        this.setState({ showMedia: true });
    }
    onRedo() {
        this.setState({
            isInPreview: false
        });

        let video = document.getElementById('my_video');
        video.src = null;
        video.controls = false;
    }

    onAccepted() {
        this.setState({ isSendingToServer: true });
        this.downloadVideo(this.state.blob, this.state.thumbnail);
    }

    handleRequest() {
        console.log('Request Recording...');
    }
    handleGranted() {
        this.setState({ granted: true });
        console.log('Permission Granted!');
    }
    handleDenied(err) {
        this.setState({ rejectedReason: err.name });
        console.log('Permission Denied!', err);
    }
    handleStart(stream) {
        this.setState({
            recording: true
        });

        this.setStreamToVideo(stream);
        console.log('Recording Started.');
    }

    handleStop(blob) {
        this.setState({
            recording: false,
            blob: blob
        });

        this.releaseStreamFromVideo();

        console.log('Recording Stopped.');
        //this.downloadVideo(blob);

        this.setState({ isInPreview: true });
        let video = document.getElementById('my_video');
        video.src = URL.createObjectURL(blob);
        video.controls = true;
        video.addEventListener('loadeddata', () => {
            this.snapImage(video);
        });
    }

    handlePause() {
        this.releaseStreamFromVideo();

        this.setState({
            paused: true
        });
    }

    handleResume(stream) {
        this.setStreamToVideo(stream);

        this.setState({
            paused: false
        });
    }

    handleError(err) {
        console.log(err);
    }
    handleStreamClose() {
        this.setState({
            granted: false
        });
    }

    setStreamToVideo(stream) {
        let video = document.getElementById('my_video');

        // if(window.URL) {
        //     console.log("s", stream);
        // 	video.src = window.URL.createObjectURL(stream.srcObject);
        // }
        // else {
        video.srcObject = stream;
        // }
    }

    releaseStreamFromVideo() {
        let video = document.getElementById('my_video');
        video.srcObject = null;
    }

    downloadVideo(blob, thumbnail) {
        this.props.onWebcamVideoStored(blob, thumbnail);
        // let url = URL.createObjectURL(blob);
        // let a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // a.download="myfile.webm";
        // a.target = '_blank';
        // document.body.appendChild(a);
        // a.click();
    }

    snapImage(video) {
        var canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            // let url = URL.createObjectURL(blob);
            // var success = url.length > 100000;
            // if (success) {
                // var img = document.createElement('img');
                // img.src = image;
                // document.getElementsByClassName('media-capturer__wrapper')[0].appendChild(img);
                console.log('Got thumbnail.');
                this.setState({
                    thumbnail: blob
                });
            // } else {
            //     console.log('No thumbnail.');
            // }
        });
    }

    dataURItoBlob(dataURI) {}

    render() {
        const granted = this.state.granted;
        const rejectedReason = this.state.rejectedReason;
        const recording = this.state.recording;
        const paused = this.state.paused;

        return (
            <div className="webcam-component__wrapper">
                <div className="background-overlay" />
                <div className="webcam-component__close-but" onClick={this.props.onExit}>
                    X
                </div>
                <div className="media-capturer__wrapper">
                    {this.state.showMedia && (
                        <MediaCapturer
                            constraints={{ audio: true, video: true }}
                            timeSlice={10}
                            onRequestPermission={this.handleRequest}
                            onGranted={this.handleGranted}
                            onDenied={this.handleDenied}
                            onStart={this.handleStart}
                            onStop={this.handleStop}
                            onPause={this.handlePause}
                            onResume={this.handleResume}
                            onError={this.handleError}
                            onStreamClosed={this.handleStreamClose}
                            render={({ request, start, stop, pause, resume }) => (
                                <div>
                                    {/* <p>Granted: {granted.toString()}</p>
                                    <p>Rejected Reason: {rejectedReason}</p>
                                    <p>Recording: {recording.toString()}</p>
                                    <p>Paused: {paused.toString()}</p> */}
                                    <div className="buttons">
                                        {!granted && !this.state.isInPreview && (
                                            <button className="get-permission" onClick={request}>
                                                Get Permission
                                            </button>
                                        )}
                                        {granted && (
                                            <div>
                                                {!this.state.recording && (
                                                    <button onClick={start}>
                                                        <img src={recordImg} />
                                                    </button>
                                                )}
                                                {/* {!this.state.paused && this.state.recording && (
                                                    <button onClick={pause}>
                                                        <img src={pauseImg} />
                                                    </button>
                                                )}
                                                {this.state.paused && (
                                                    <button onClick={resume}>
                                                        <img src={resumeImg} />
                                                    </button>
                                                )} */}
                                                {this.state.recording && !this.state.paused && (
                                                    <button onClick={stop}>
                                                        <img src={previewImg} />
                                                    </button>
                                                )}
                                            </div>
                                        )}

                                        {this.state.isInPreview && (
                                            <button onClick={this.onRedo}>
                                                <img src={undoImg} />
                                            </button>
                                        )}

                                        {this.state.isInPreview && (
                                            <button onClick={this.onAccepted}>
                                                <img src={doneImg} />
                                            </button>
                                        )}
                                    </div>
                                    <video autoPlay muted id="my_video" ref={this.myVideoRef} />

                                    {this.state.isSendingToServer && <LoadingComponent className="loading-component" />}
                                    {!this.state.isInPreview && <h1>{this.props.question}</h1>}
                                    {this.state.recording && (
                                        <p className="notification">{this.props.t('recording')}</p>
                                    )}
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default WebcamComponent;
